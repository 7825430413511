import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import {navigateToParametrized, getQueryParam} from '../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchQuotationMakeOrder} from '../../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';
import ButtonFetch from '../../fetch/ButtonFetch';
import { CartOrderFormValues, Location, QuotationDetail } from '../../../constants/propTypesDefinitions';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import { RIGHTS } from '../../../constants/Rights';
import { TRANSPORT_TYPE } from '../../../constants/transportTypes';
import { isAddressRequired } from '../../../lib/project';
/**
 * @fero
 */


class QuotationMakeOrder extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        quotationDetails: QuotationDetail.isRequired,
        params: CartOrderFormValues.isRequired,
    };

    orderAttributes = () => {
        const {location, quotationDetails, params} = this.props;
        const quotationOrderItemsParam = getQueryParam(location, QUERY_PARAMS.QUOTATION_ORDER_ITEMS);
        const quotationOrderItems = quotationOrderItemsParam != null ?
            JSON.parse(getQueryParam(location, QUERY_PARAMS.QUOTATION_ORDER_ITEMS)) : undefined;

        return {
            id_quotation: quotationDetails.id,
            id_invoice_address: params.invoiceAddressId,
            id_delivery_address: params.deliveryAddressId,
            id_transport_type: params.transportTypeId,
            id_payment_method: params.paymentMethodId,
            customer_reference: params.customerReference,
            customer_notices: params.customerNotices,
            is_divisible: params.isDivisible,
            is_critical: params.isCritical,
            items: quotationOrderItems,
            final_order: params.isProxy ? {
                order_reference: params.finalOrderRef,
                customer: {
                    name: params.finalCustomerName
                },
                user: {
                    fullname: params.finalUserName,
                    e_mail: params.finalUserEmail,
                    phone: params.finalUserPhone,
                    id_language: params.finalUserLanguage,
                },
                delivery_address: {
                    street: params.finalAddressStreet,
                    zip_code: params.finalAddressZipCode,
                    city: params.finalAddressCity,
                    id_country: params.finalAddressCountryId,
                }
            } : undefined,
        };
    };

    navigateToOrderDetail = (orderId) => {
        const {location} = this.props;
        navigateToParametrized(location, ROUTES.ORDER_DETAILS, {
            [QUERY_PARAMS.ID_ORDER]: orderId,
        });
    };

    render() {
        const {disabled, params, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const orderAttributes = this.orderAttributes();
        let requiredNotSet = (
            orderAttributes.id_quotation == null ||
            orderAttributes.id_transport_type == null ||
            orderAttributes.id_payment_method == null
        );
    
        if(params.isProxy)
        {
            // check if proxy params are set
            if(params.finalCustomerName == null || params.finalAddressStreet == null 
                || params.finalAddressZipCode == null || params.finalAddressCity == null || params.finalAddressCountryId == null)
                requiredNotSet = true;
        }
        else
        {
            // standard order
            const addressRequired = isAddressRequired(rights, params.transportTypeId);
            if(addressRequired && (params.invoiceAddressId == null || params.deliveryAddressId == null))
                requiredNotSet = true;
        }

        return <div>
            <ButtonFetch
                type="primary"
                disabled={requiredNotSet}
                fetchFunction={createFetchQuotationMakeOrder(orderAttributes)}
                values={{}}
                onFinish={this.navigateToOrderDetail}
                Failed={generalFailedPC(t`Nepodarilo sa vytvoriť objednávku.`)}
            >
                <Trans>Vytvoriť objednávku</Trans>
            </ButtonFetch>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(locationHOC(QuotationMakeOrder));